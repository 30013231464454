import React from "react";
import "../About/about.css";
import "../Home/home.css";
import "../../assets/scss/about.scss";
import { Container } from "../../style";
import { useTranslation } from "react-i18next";
import Testimonial from "../../components/Testimonial/Testimonial";
import { Helmet } from "react-helmet";

import staradimo from "../../assets/Img/staradimo.png";
import kosmomi from "../../assets/Img/kosmomi.png";
import aboutHeder from "../../assets/Img/aboutHeder.jpeg";
import Clients from "../../components/Clients/Clients";

export default function About() {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>
          Izrada web sajtova i digitalni marketing sa strašću i posvećenošću ka
          unapređenju Vašeg poslovanja
        </title>
        <meta
          name="description"
          content="Mi smo tim stručnjaka za izradu web sajtova i digitalni marketing koji se ističe visokim nivoom profesionalnosti i strastvenim pristupom. Naša posvećenost prema vašem uspehu je neupitna, a naša stručnost je zasnovana na dugogodišnjem iskustvu i kontinuiranom praćenju najnovijih trendova. Sa kreativnim pristupom i preciznošću, pružamo personalizovana rešenja koja odražavaju jedinstvenost vašeg brenda i privlače ciljanu publiku. Naša stranica &quot;O Nama&quot; otkriva našu duboku stručnost i profesionalizam, dok istovremeno pruža uvid u našu viziju i vrednosti koje nas vode. Radujemo se što ćemo vam pomoći da podignete svoje poslovanje na viši nivo i ostvarite izvanredne rezultate."
        />
      </Helmet>

      <Container>
        <div className="about">
          <h1 dangerouslySetInnerHTML={{ __html: t("about.title") }} />
          <p>
            {t("about.text1")}
          </p>
        </div>

        <div className="about-bg">
          <img className="about-heder" src={aboutHeder} alt="About Heder" />
        </div>
        <div className="vision">
          <div className="item">
            <div className="item-text">
              <h3 className="sub-title" style={{ textAlign: "start" }}>
                {t("about.kosmomi_title")}
              </h3>
              <p>
                {t("about.kosmomi_text")}
              </p>
            </div>
            <div className="item-img">
              <img src={kosmomi} alt="" />
            </div>
          </div>
          <div className="item">
            <div className="item-img">
              <img src={staradimo} alt="" />
            </div>
            <div className="item-text">
              <h3 className="sub-title" style={{ textAlign: "start" }}>
                {t("about.staradimo_title")}
              </h3>
              <p>
                {t("about.staradimo_text")}
              </p>
            </div>
          </div>

          <div class="ribbon-container">
            <center>
              <h2 className="sub-title">
                {t("about.izaberinas.title")}
              </h2>
            </center>
            <div class="ribbon">
              <div className="ribbon-text">
                <div className="number">O1</div>
                <div className="text">
                  <h3>
                    {t("about.izaberinas.subtitle1")}
                  </h3>
                  <p>
                    {t("about.izaberinas.text1")}
                  </p>
                </div>
              </div>
            </div>
            <div class="ribbon">
              <div className="ribbon-text">
                <div className="number">O2</div>
                <div className="text">
                  <h3>
                    {t("about.izaberinas.subtitle2")}
                  </h3>
                  <p>
                    {t("about.izaberinas.text2")}
                  </p>
                </div>
              </div>
            </div>
            <div class="ribbon">
              <div className="ribbon-text">
                <div className="number">O3</div>
                <div className="text">
                  <h3>
                    {t("about.izaberinas.subtitle3")}
                  </h3>
                  <p>
                    {t("about.izaberinas.text3")}
                  </p>
                </div>
              </div>
            </div>
            <div class="ribbon">
              <div className="ribbon-text">
                <div className="number">O4</div>
                <div className="text">
                  <h3>
                    {t("about.izaberinas.subtitle4")}
                  </h3>
                  <p>
                    {t("about.izaberinas.text4")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Testimonial />
      <Clients />
    </div>
  );
}
