import styled from "styled-components";

export const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  padding-top: 100px;


  @media (min-width: 1400px) {
    width: 1200px;
  }
  @media (max-width: 1400px) {
    width: 90%;
  }
`;
